import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';

import IndividualProduct from './IndividualProduct';
import Pagination from '../../components/Pagination';
import CatalogStats from '../../components/CatalogStats';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

function CatalogPage(props) {
  const {
    storeName,
    gatsbyImageProps,
  } = props.data.store.siteMetadata.gatsbyStorefrontConfig;
  const { limit, skip, cartUrl } = props.pageContext;
  const { title, description, handle } = props.data.catalog.nodes[0];
  const { location } = props;
  let products = [];

  props.data.catalog.nodes.forEach((node) => {
    products = [...products, ...node.products];
  });

  return (
    <>
     <GatsbySeo
        title={title}
        titleTemplate={`%s — ${storeName}`}
        description={description}
        openGraph={{
          url: location.href,
          title: title,
          description: description,
          images:[{
            url: location.origin + '/images/logo_800_276.png',
            width: 800,
            height: 276,
          },
          {
            url: location.origin + '/images/logo_800_800.png',
            width: 800,
            height: 800,
          },
          {
            url: location.origin + '/images/logo_382_113.png',
            width: 382,
            height: 113,
          }],
        }}
      />
      <Flex flexWrap="wrap" px={2} pt={3} mx="auto" style={{ maxWidth: 1300 }}>
        <Flex
          width={1}
          px={4}
          py={2}
          flexWrap={['wrap', 'nowrap']}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Text textAlign="left">
            <Heading as="h1" fontSize={[30, 36, 42]}>
              {title}
            </Heading>
            <Text fontSize={[2, 3]}>{description}</Text>
          </Text>
          <Text
            textAlign={['center', 'right']}
            width={['100%', 'auto']}
            pt={1}
            fontSize={[1, 2, 3]}
          >
            <CatalogStats
              limit={limit}
              skip={skip}
              productsNum={products.length}
            />
          </Text>
        </Flex>
        {products.map((product, index) => {
          product.cartUrl = cartUrl;
          if (index + 1 > skip && index + 1 <= skip + limit) {
            return (
              <IndividualProduct
                key={product.shopifyId}
                product={product}
                gatsbyImageProps={gatsbyImageProps}
              />
            );
          } else {
            return '';
          }
        })}
        <Box width={1} px={4} py={2} key="pagination">
          <Text textAlign="center">
            <Pagination {...props.pageContext} />
          </Text>
        </Box>
      </Flex>
    </>
  );
}

export default CatalogPage;

import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../../components/Layout';
import CatalogPage from './CatalogPage';

const Catalog = (props) => {
  const { title, description } = props.data.catalog.nodes[0];
  const { storeName } = props.data.store.siteMetadata.gatsbyStorefrontConfig;
  return (
    <Layout>
      <Helmet title={title} titleTemplate={`%s — ${storeName}`} defer={false}>
        <meta name="description" content={description} />
      </Helmet>
      <CatalogPage {...props} />
    </Layout>
  );
};
export default Catalog;

export const catalogQuery = graphql`query CatalogQuery($handle: String) {
  catalog: allShopifyCollection(filter: {handle: {eq: $handle}}) {
    nodes {
      title
      handle
      description
      products {
        id
        shopifyId
        title
        tags
        fields {
          shopifyThemePath          
        }
        featuredImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 450
                height: 450
                backgroundColor: "white"
                breakpoints: [450]
                transformOptions: {cropFocus: ATTENTION, fit: COVER}
                layout: CONSTRAINED
              )
            }
          }
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        variants {
          shopifyId
          availableForSale
          compareAtPrice
          price
        }
      }
    }
  }
  store: site {
    siteMetadata {
      gatsbyStorefrontConfig {
        storeName
        gatsbyImageProps {
          loading
          fadeIn
          durationFadeIn
        }
      }
    }
  }
}
`;
